export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_PORTS = "GET_PORTS";
export const GET_SUCCESS = "LIST_SUCCESS";
export const GET_FAIL = "GET_FAIL";
export const GET_EXPORT = "GET_EXPORT";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const SUBMIT_FAIL = "SUBMIT_FAIL";
export const SUBMIT_CLEAR = "SUBMIT_CLEAR";
export const GET_QUANTITY_SUCCESS = "GET_QUANTITY_SUCCESS";
export const LOAD_ON_QUANTITY_REQUEST = "LOAD_ON_QUANTITY_REQUEST";

