export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_PORTS = "GET_PORTS";
export const LOAD_ON_REQUEST = "LOAD_ON_REQUEST";
export const GET_SUCCESS = "LIST_SUCCESS";
export const GET_FAIL = "GET_FAIL";
export const GET_EXPORT = "GET_EXPORT";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const SUBMIT_FAIL = "SUBMIT_FAIL";
export const SUBMIT_CLEAR = "SUBMIT_CLEAR";
export const GET_DUYET_SUCCESS = "GET_DUYET_SUCCESS";
export const GET_HUONGDAN_FAIL = "GET_HUONGDAN_FAIL";
export const GET_HUONGDAN_SUCCESS = "GET_HUONGDAN_SUCCESS";
export const LOAD_ON_DUYET_REQUEST = "LOAD_ON_DUYET_REQUEST";
export const SUBMIT_CREATE_SUCCESS = "SUBMIT_CREATE_SUCCESS";
export const GET_UPDATE_SUCCESS = "GET_UPDATE_SUCCESS";

