import {
  CLEAR_ERRORS,
  LOAD_ON_QUANTITY_REQUEST,
  GET_SUCCESS,
  GET_FAIL,
  GET_EXPORT,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_QUANTITY_SUCCESS,

} from "../constants/quantityConstants";

export const quantityReducer = (state = { quantity: {} }, action) => {
  switch (action.type) {
    case LOAD_ON_QUANTITY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        is_redirect_quantity: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        quantity: action.payload,
      };
    case GET_QUANTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        quantity: action.payload,
      };
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        quantity: {},
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success_submit: action.payload.message,
        is_redirect_quantity: true,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error_submit: action.payload,
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        error_submit: null,
        success_submit: null,
        is_redirect_quantity: null
      };
    default:
      return state;
  }
};
