import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  allUsersReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducers/userReducer";

import { portReducer } from "./reducers/portReducer";
import { changeImeiReducer } from "./reducers/transactionReducer";
import { addFriendReducer } from "./reducers/addFriendReducer";
import { banggiaReducer } from "./reducers/banggiaReducer";
import { thongbaoReducer } from "./reducers/thongbaoReducer";
import { soduReducer } from "./reducers/soduReducer";
import { accountReducer } from "./reducers/accountReducer";
import { quantityReducer } from "./reducers/quantityReducer";
import { msaleReducer } from "./reducers/msaleReducer";
import { provinceReducer } from "./reducers/provinceReducer";
import { packageBuyReducer } from "./reducers/packagebuyReducer";
import { duyetReducer } from "./reducers/duyetReducer";
import { vnskyReducer } from "./reducers/vnskyReducer";
import { myitelReducer } from "./reducers/myitelReducer";
import { gmobileReducer } from "./reducers/gmobileReducer";
import { orderReducer } from "./reducers/orderReducer";
import { permissionReducer } from "./reducers/permissionReducer";


const reducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  port: portReducer,
  changeImei: changeImeiReducer,
  banggia: banggiaReducer,
  thongbao: thongbaoReducer,
  sodu: soduReducer,
  friend: addFriendReducer,
  account: accountReducer,
  quantity: quantityReducer,
  msale: msaleReducer,
  province: provinceReducer,
  packageBuy: packageBuyReducer,
  duyet: duyetReducer,
  vnsky: vnskyReducer,
  myitel: myitelReducer,
  gmobile: gmobileReducer,
  order: orderReducer,
  permission: permissionReducer,
});




let initialState = {

};

const middleware = [thunk];

// const store = createStore(
//   reducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const devTools =
  process.env.NODE_ENV !== "production"
    ? applyMiddleware(...middleware)
    : composeWithDevTools(applyMiddleware(...middleware));
const store = createStore(reducer, initialState, devTools);
export default store;
