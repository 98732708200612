import {
  CLEAR_ERRORS,
  LOAD_ON_REQUEST,
  GET_SUCCESS,
  GET_FAIL,
  GET_EXPORT,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_PORT_SUCCESS,
} from "../constants/portConstants";

export const portReducer = (state = { port: {} }, action) => {
  switch (action.type) {
    case LOAD_ON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        redirect: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        port: action.payload,
      };
    case GET_PORT_SUCCESS:
      return {
        ...state,
        loading: false,
        port: action.payload,
      };

    case GET_EXPORT:
      return {
        ...state,
        loading: false,
        download: action.payload
      };
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        port: {},
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success_submit: action.payload,
        is_redirect: true,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error_submit: action.payload,
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        error_submit: null,
        success_submit: null,
        is_redirect: null
      };
    default:
      return state;
  }
};
