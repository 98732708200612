import React, { } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { logout } from '../../actions/userAction';
import { useDispatch, useSelector } from "react-redux";
import { getThongbaoTatca } from '../../actions/ThongbaoAction';

const Navbar = () => {
  const { user } = useSelector((state) => state.user);
  const { thongbao } = useSelector((state) => state.thongbao);
  const dispatch = useDispatch();
  const logoutSubmit = (e) => {
    dispatch(logout());
  };
  let history = useHistory();
  const thongbaoChitiet = (e) => {
    dispatch(getThongbaoTatca({
      id: e.target.dataset.item || "",
    }));
    history.push('/thongbao/tatca');
  }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <Link className="navbar-brand brand-logo" to="/"><img src={require('../../assets/images/logo.svg')} alt="logo" /></Link>
        <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="mdi mdi-menu"></span>
        </button>
        {/* <div className="search-field d-none d-md-block">
          <form className="d-flex align-items-center h-100" action="#">
            <div className="input-group">
              <div className="input-group-prepend bg-transparent">
                <i className="input-group-text border-0 mdi mdi-magnify"></i>
              </div>
              <input type="text" className="form-control bg-transparent border-0" placeholder="Search projects" />
            </div>
          </form>
        </div> */}

        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link " >
                <div className="nav-profile-img">
                  <img src={require("../../assets/images/faces/face1.jpg")} alt="user" />
                  <span className="availability-status online"></span>
                </div>
                <div className="nav-profile-text">
                  <div className=" text-black"><span><Trans>{(user && user.name) || ''}</Trans></span><br /><span className='mt-3'>Số dư: {(user && user.balance) || 0}vnd</span></div>
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item as={Link} to="/profile">
                  <i className="mdi mdi-cached mr-2 text-success"></i>
                  <Trans>Thông tin tài khoản</Trans>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/change-password">
                  <i className="mdi mdi-pi mr-2 text-success"></i>
                  <Trans>Đổi mật khẩu</Trans>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/so-du">
                  <i className="mdi mdi-history mr-2 text-success"></i>
                  <Trans>Lịch sử số dư</Trans>
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/bang-gia">
                  <i className="mdi mdi-apps mr-2 text-success"></i>
                  <Trans>Bảng giá dịch vụ</Trans>
                </Dropdown.Item>
                {/* <Dropdown.Item as={Link} to="/qty-list">
                  <i className="mdi mdi-cached mr-2 text-success"></i>
                  <Trans>Gói đang dùng</Trans>
                </Dropdown.Item> */}
                {(user && user.add_friend === 1 && (
                  <Dropdown.Item as={Link} to="/add-friend/list">
                    <i className="mdi mdi-chemical-weapon mr-2 text-success"></i>
                    <Trans>Giới thiệu bạn bè</Trans>
                  </Dropdown.Item>
                )) || ''}

                <Dropdown.Item href="!#" onClick={logoutSubmit}>
                  <i className="mdi mdi-logout mr-2 text-primary"></i>
                  <Trans>Đăng xuất</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="mdi mdi-bell-outline"></i>
                <span className="count-symbol ">{(thongbao && thongbao.tong_chua_doc) || 0}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list">
                <h6 className="p-3 mb-0"><Trans>Thông Báo</Trans></h6>
                {thongbao && thongbao.data && thongbao.data.length > 0 && (
                  thongbao.data.map((item, key) =>
                    <Dropdown.Item className="dropdown-item preview-item" key={item.id}>
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-success">
                          <i className="mdi mdi-calendar"></i>
                        </div>
                      </div>
                      <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                        <h6 className="preview-subject font-weight-normal mb-1"><Trans>{item.ten}</Trans><i className="mdi mdi-new-box text-danger ml-2"></i></h6>
                        <p className="text-gray ellipsis mb-0" data-item={item.id} onClick={thongbaoChitiet}>
                          <Trans>{item.mota}</Trans>
                        </p>
                      </div>
                    </Dropdown.Item>
                  )
                )}

                <div className="dropdown-divider"></div>
                <h6 className="p-3 mb-0 text-center cursor-pointer">
                  <Link to="/thongbao/tatca">
                    Xem tất cả
                  </Link></h6>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item nav-logout d-none d-lg-block">
            <a className="nav-link" href="!#" onClick={logoutSubmit}>
              <i className="mdi mdi-power"></i>
            </a>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={() => document.querySelector('.sidebar-offcanvas').classList.toggle('active')}>
          <span className="mdi mdi-menu"></span>
        </button>

      </div>
    </nav>
  );
}

export default Navbar;
