export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_FAIL = "GET_FAIL";
export const GET_PROVINCE_SUCCESS = "GET_PROVINCE_SUCCESS";
export const LOAD_ON_PROVINCE_REQUEST = "LOAD_ON_PROVINCE_REQUEST";
export const GET_DISTRICT_SUCCESS = "GET_DISTRICT_SUCCESS";
export const GET_WARD_SUCCESS = "GET_WARD_SUCCESS";
export const SUBMIT_CLEAR = "SUBMIT_CLEAR";
export const SUBMIT_FAIL = "SUBMIT_FAIL";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const DISTRICT_CLEAR = "DISTRICT_CLEAR";
export const WARD_CLEAR = "WARD_CLEAR";



