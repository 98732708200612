export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_PORTS = "GET_PORTS";
export const LOAD_GMOBILE_REQUEST = "LOAD_GMOBILE_REQUEST";
export const GET_SUCCESS = "LIST_SUCCESS";
export const GET_FAIL = "GET_FAIL";
export const GET_EXPORT = "GET_EXPORT";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const SUBMIT_FAIL = "SUBMIT_FAIL";
export const SUBMIT_CLEAR = "SUBMIT_CLEAR";
export const GET_GMOBLE_DKTT_SUCCESS = "GET_GMOBLE_DKTT_SUCCESS";
export const GET_HUONGDAN_FAIL = "GET_HUONGDAN_FAIL";
export const GET_HUONGDAN_SUCCESS = "GET_HUONGDAN_SUCCESS";
export const LOAD_MSALE_REQUEST = "LOAD_MSALE_REQUEST";
export const GET_GMOBLE_DKTT_GPKD_SUCCESS = "GET_GMOBLE_DKTT_GPKD_SUCCESS";
export const GET_GMOBILE_SEARCH_PHONE_SUCCESS = "GET_GMOBILE_SEARCH_PHONE_SUCCESS";
export const GET_GMOBLE_DKTT_DETAIL_SUCCESS = "GET_GMOBLE_DKTT_DETAIL_SUCCESS";
