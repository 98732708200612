import {
  CLEAR_ERRORS,
  LOAD_ON_PROVINCE_REQUEST,
  GET_FAIL,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_PROVINCE_SUCCESS,
  GET_DISTRICT_SUCCESS,
  GET_WARD_SUCCESS,
  DISTRICT_CLEAR,
  WARD_CLEAR,
} from "../constants/provinceConstants";

export const provinceReducer = (state = { province: {} }, action) => {
  switch (action.type) {
    case LOAD_ON_PROVINCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        redirect: null,
        redirect_role: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_PROVINCE_SUCCESS:
      return {
        ...state,
        loading: false,
        api_province: action.payload.data,
        success_submit: action.payload.message,
        province_check: true,
      };
    case GET_DISTRICT_SUCCESS:
      return {
        ...state,
        loading: false,
        api_district: action.payload.data,
        success_submit: action.payload.message,
        district_check: true,
      };
    case GET_WARD_SUCCESS:
      return {
        ...state,
        loading: false,
        api_ward: action.payload.data,
        success_submit: action.payload.message,
        ward_check: true,
      };
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        detail: {},
        success_submit: action.payload.message,
        is_redirect: true,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error_submit: action.payload,
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        error_submit: null,
        success_submit: null,
        is_redirect: null
      };
    case DISTRICT_CLEAR:
      return {
        ...state,
        loading: false,
        api_district: {},
        api_ward: {},
        district_check: false,
      };
    case WARD_CLEAR:
      return {
        ...state,
        loading: false,
        api_ward: {},
        ward_check: false,
      };
    default:
      return state;
  }
};
