export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_PORTS = "GET_PORTS";
export const LOAD_ON_REQUEST = "LOAD_ON_REQUEST";
export const GET_SUCCESS = "LIST_SUCCESS";
export const GET_FAIL = "GET_FAIL";
export const GET_EXPORT = "GET_EXPORT";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const SUBMIT_FAIL = "SUBMIT_FAIL";
export const SUBMIT_CLEAR = "SUBMIT_CLEAR";
export const GET_HUONGDAN_SUCCESS = "LIST_HUONGDAN_SUCCESS";
export const GET_HUONGDAN_FAIL = "LIST_HUONGDAN_SUCCESS";
export const GET_CHANGE_IMEI_SUCCESS = "GET_CHANGE_IMEI_SUCCESS";
export const UPDATE_STATUS_ERROR = "UPDATE_STATUS_ERROR";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const GET_ACCOUNT_MY_SUCCESS = "GET_ACCOUNT_MY_SUCCESS";
export const GET_SENT_SMS_SUCCESS = "GET_SENT_SMS_SUCCESS";
export const GET_USSD_SUCCESS = "GET_USSD_SUCCESS";
export const GET_CALL_SUCCESS = "GET_CALL_SUCCESS";
export const GET_MULTIPLE_CMD_SUCCESS = "GET_MULTIPLE_CMD_SUCCESS";
export const GET_MULTIPLE_CMD_SCRIPT_SUCCESS = "GET_MULTIPLE_CMD_SCRIPT_SUCCESS";

