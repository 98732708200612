import {
  CLEAR_ERRORS,
  LOAD_MSALE_REQUEST,
  GET_SUCCESS,
  GET_FAIL,
  GET_EXPORT,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_DNTS_SUCCESS,
  GET_HUONGDAN_SUCCESS,
  GET_HUONGDAN_FAIL,
  GET_DNTS_GPKD_SUCCESS,
  GET_DNTS_IMAGE_GPKD_SUCCESS,
  GET_DNTS_IMAGE_GPKD_ERROR,
  GET_DNCNGT_SUCCESS,

} from "../constants/msaleConstants";

export const msaleReducer = (state = { msale: {} }, action) => {
  switch (action.type) {
    case LOAD_MSALE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        redirect: null,
        redirect_role: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload,
      };
    case GET_HUONGDAN_SUCCESS:
      return {
        ...state,
        loading: false,
        huongdan: action.payload,
      };
    case GET_HUONGDAN_FAIL:
      return {
        ...state,
        loading: false,
        huongdan: {},
        message: action.payload,
      };
    case GET_DNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        list_dnts_dn: action.payload,
      };
    case GET_DNCNGT_SUCCESS:
      return {
        ...state,
        loading: false,
        list_dngt_dn: action.payload,
      };
    case GET_DNTS_IMAGE_GPKD_SUCCESS:
      return {
        ...state,
        image_gpkd_hd: action.payload,
        image_gpkd_hd_check: true,
      };
    case GET_DNTS_IMAGE_GPKD_ERROR:
      return {
        ...state,
        image_gpkd_hd: false,
        image_gpkd_hd_check: false,
      };

    case GET_EXPORT:
      return {
        ...state,
        loading: false,
        download: action.payload,
      };
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        list_dnts_dn: {},
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success_submit: action.payload.message,
        is_redirect: true,
      };
    case GET_DNTS_GPKD_SUCCESS:
      return {
        ...state,
        loading: false,
        get_gpkd: action.payload.data.gpkd,
        get_bus: action.payload.data.bus,
        success_submit: action.payload.message,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error_submit: action.payload,
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        detail: {},
        error_submit: null,
        success_submit: null,
        is_redirect: null
      };
    default:
      return state;
  }
};
