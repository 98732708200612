export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const GET_SUCCESS = "LIST_SUCCESS";
export const GET_FAIL = "GET_FAIL";
export const GET_EXPORT = "GET_EXPORT";
export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS";
export const SUBMIT_FAIL = "SUBMIT_FAIL";
export const SUBMIT_CLEAR = "SUBMIT_CLEAR";
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS";
export const LOAD_ON_PERMISSION_REQUEST = "LOAD_ON_PERMISSION_REQUEST";
export const GET_HUONGDAN_FAIL = "GET_HUONGDAN_FAIL";
export const GET_HUONGDAN_SUCCESS = "GET_HUONGDAN_SUCCESS";


