import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";


class App extends Component {
  state = {}
  componentDidMount() {
    // this.onRouteChanged();
  }
  render () {
    window.scrollTo(0, 0);
    let navbarComponent = (this.props.location.pathname !== "/dang-nhap") ? <Navbar/> : '';
    let sidebarComponent = (this.props.location.pathname !== "/dang-nhap") ? <Sidebar/> : '';
    let SettingsPanelComponent = (this.props.location.pathname !== "/dang-nhap") ? <SettingsPanel/> : '';
    let footerComponent = (this.props.location.pathname !== "/dang-nhap") ? <Footer/> : '';
    let showFull = (this.props.location.pathname !== "/dang-nhap") ? "container-fluid page-body-wrapper " : 'container-fluid page-body-wrapper full-page-wrapper';
    return (
      <div className="container-scroller">
        { navbarComponent }
        <div className={showFull}>
          { sidebarComponent }
          <div className="main-panel">
            <div className="content-wrapper">
              <AppRoutes/>
              { SettingsPanelComponent }
            </div>
            { footerComponent }
          </div>
        </div>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      // this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    
    // const { i18n } = this.props;
    const body = document.querySelector('body');
    if(this.props.location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      // i18n.changeLanguage('ar');
    }
    else {
      body.classList.remove('rtl')
    }
    window.scrollTo(0, 0);
  }

}

export default withTranslation() (withRouter(App));
