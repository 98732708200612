import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/msale/dncngt', state: 'msaleDnCnGtMenuOpen' },
      { path: '/msale/dnts', state: 'msaleDntsMenuOpen' },
      { path: '/account', state: 'accountMenuOpen' },
      { path: '/duyet', state: 'duyetMenuOpen' },
      { path: '/vnsky', state: 'vnskyDkttMenuOpen' },
      { path: '/myitel', state: 'myitelDkttMenuOpen' },
      { path: '/gmobile/dktt', state: 'gmobileDkttMenuOpen' },
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true })
      }
    }));

  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title"><Trans>Trang chủ</Trans></span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {/* <li className={this.isPathActive('/account') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/account/list">
              <span className="menu-title"><Trans>Tài khoản</Trans></span>
              <i className="mdi mdi-account menu-icon"></i>
            </Link>
          </li>
          <li className={this.isPathActive('/duyet') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/duyet/list">
              <span className="menu-title"><Trans>MsalePro</Trans></span>
              <i className="mdi mdi-account menu-icon"></i>
            </Link>
          </li> */}
          <li className={this.isPathActive('/gmobile/dktt') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.gmobileDkttMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('gmobileDkttMenuOpen')} data-toggle="collapse">
              <span className="menu-title"><Trans>GMOBILE DKTT</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={this.state.gmobileDkttMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/huong-dan') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/huong-dan"><Trans>Hướng dẫn sử dụng</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/order') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/order"><Trans>Quản lý đơn</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/create') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/create"><Trans>Khởi tạo</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/gmobile/dktt/list') ? 'nav-link active' : 'nav-link'} to="/gmobile/dktt/list"><Trans>Lịch sử giao dịch</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          {/* <li className={this.isPathActive('/msale/dnts') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.msaleDntsMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('msaleDntsMenuOpen')} data-toggle="collapse">
              <span className="menu-title"><Trans>Đấu nối trả sau</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={this.state.msaleDntsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/msale/dnts/huong-dan') ? 'nav-link active' : 'nav-link'} to="/msale/dnts/huong-dan"><Trans>Hướng dẫn sử dụng</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/msale/dnts/create') ? 'nav-link active' : 'nav-link'} to="/msale/dnts/create"><Trans>Khởi tạo</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/msale/dnts/list') ? 'nav-link active' : 'nav-link'} to="/msale/dnts/list"><Trans>Lịch sử giao dịch</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={this.isPathActive('/msale/dncngt') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.msaleDnCnGtMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('msaleDnCnGtMenuOpen')} data-toggle="collapse">
              <span className="menu-title"><Trans>Đấu nối gián tiếp TT</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={this.state.msaleDnCnGtMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/msale/dncngt/huong-dan') ? 'nav-link active' : 'nav-link'} to="/msale/dncngt/huong-dan"><Trans>Hướng dẫn sử dụng</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/msale/dncngt/create') ? 'nav-link active' : 'nav-link'} to="/msale/dncngt/create"><Trans>Khởi tạo</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/msale/dncngt/list') ? 'nav-link active' : 'nav-link'} to="/msale/dncngt/list"><Trans>Lịch sử giao dịch</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={this.isPathActive('/vnsky') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.vnskyDkttMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('vnskyDkttMenuOpen')} data-toggle="collapse">
              <span className="menu-title"><Trans>VNSKY</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={this.state.vnskyDkttMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/vnsky/huong-dan') ? 'nav-link active' : 'nav-link'} to="/vnsky/huong-dan"><Trans>Hướng dẫn sử dụng</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/vnsky/create') ? 'nav-link active' : 'nav-link'} to="/vnsky/create"><Trans>Khởi tạo</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/vnsky/list') ? 'nav-link active' : 'nav-link'} to="/vnsky/list"><Trans>Lịch sử giao dịch</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
          {/* <li className={this.isPathActive('/myitel') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.myitelDkttMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('myitelDkttMenuOpen')} data-toggle="collapse">
              <span className="menu-title"><Trans>MYITEL</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
            </div>
            <Collapse in={this.state.myitelDkttMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/myitel/huong-dan') ? 'nav-link active' : 'nav-link'} to="/myitel/huong-dan"><Trans>Hướng dẫn sử dụng</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/myitel/create') ? 'nav-link active' : 'nav-link'} to="/myitel/create"><Trans>Khởi tạo</Trans></Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/myitel/list') ? 'nav-link active' : 'nav-link'} to="/myitel/list"><Trans>Lịch sử giao dịch</Trans></Link></li>
              </ul>
            </Collapse>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);