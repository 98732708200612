import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import ProtectedRoute from '../Route/ProtectedRoute';
import ChangePasswordProfile from './profile/ChangePass';

const Dashboard = lazy(() => import('./dashboard/Dashboard'));
const DashboardDaunoi = lazy(() => import('./dashboard/DashboardDaunoi'));
// const Buttons = lazy(() => import('./basic-ui/Buttons'));
// const Dropdowns = lazy(() => import('./basic-ui/Dropdowns'));
// const Typography = lazy(() => import('./basic-ui/Typography'));
// const BasicElements = lazy(() => import('./form-elements/BasicElements'));
// const BasicTable = lazy(() => import('./tables/BasicTable'));
const Mdi = lazy(() => import('./icons/Mdi'));
// const ChartJs = lazy(() => import('./charts/ChartJs'));
// const Error404 = lazy(() => import('./error-pages/Error404'));
// const Error500 = lazy(() => import('./error-pages/Error500'));
// const Register1 = lazy(() => import('./user-pages/Register'));
// const Lockscreen = lazy(() => import('./user-pages/Lockscreen'));
// const BlankPage = lazy(() => import('./general-pages/BlankPage'));
const OrderList = lazy(() => import('./order/List'));
const Login = lazy(() => import('./user-pages/Login'));
const InfoProfile = lazy(() => import('./profile/Info'));
const ChangeImeiCreate = lazy(() => import('./change-imei/Create'));
const ChangeImeiList = lazy(() => import('./change-imei/List'));
const ChangeImeiHuongDan = lazy(() => import('./change-imei/Huongdan'));
const BanggiaList = lazy(() => import('./banggia/List'));
const ThongbaoList = lazy(() => import('./thongbao/List'));
const AccountMyCreate = lazy(() => import('./account-my/Create'));
const AccountMyList = lazy(() => import('./account-my/List'));
const AccountMyHuongDan = lazy(() => import('./account-my/Huongdan'));


const AddFriendCreate = lazy(() => import('./add-friend/Create'));
const AddFriendList = lazy(() => import('./add-friend/List'));
const AddFriendHuongDan = lazy(() => import('./add-friend/Huongdan'));

const PermissionCreate = lazy(() => import('./permission/Create'));
const PermissionList = lazy(() => import('./permission/List'));

const AccountCreate = lazy(() => import('./account/Create'));
const AccountList = lazy(() => import('./account/List'));
const AccountEdit = lazy(() => import('./account/Edit'));

const DuyetCreate = lazy(() => import('./duyet/Create'));
const DuyetList = lazy(() => import('./duyet/List'));

const QuantityList = lazy(() => import('./quantity/List'));
const MsaleDntsCreate = lazy(() => import('./msale-dnts/Create'));
const MsaleDntsList = lazy(() => import('./msale-dnts/List'));
const MsaleDntsHuongDan = lazy(() => import('./msale-dnts/Huongdan'));

const MsaleDnCnGtCreate = lazy(() => import('./msale-daunoi-giantiep/Create'));
const MsaleDnCnGtList = lazy(() => import('./msale-daunoi-giantiep/List'));
const MsaleDnCnGtHuongDan = lazy(() => import('./msale-daunoi-giantiep/Huongdan'));

const SoduList = lazy(() => import('./sodu/List'));
const PackageBuyList = lazy(() => import('./package-buy/List'));

const VnskyDkttCreate = lazy(() => import('./vnsky/Create'));
const VnskyDkttList = lazy(() => import('./vnsky/List'));
const VnskyDkttHuongDan = lazy(() => import('./vnsky/Huongdan'));

const MyItelDkttCreate = lazy(() => import('./myitel/Create'));
const MyItelDkttList = lazy(() => import('./myitel/List'));
const MyItelDkttHuongDan = lazy(() => import('./myitel/Huongdan'));

const GmobileDkttCreate = lazy(() => import('./gmobile-dktt/Create'));
const GmobileDkttList = lazy(() => import('./gmobile-dktt/List'));
const GmobileDkttHuongDan = lazy(() => import('./gmobile-dktt/Huongdan'));



class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* <ProtectedRoute exact path="/dashboard" component={Dashboard} /> */}
          <ProtectedRoute exact path="/dashboard" component={DashboardDaunoi} />
          {/* <Route path="/basic-ui/buttons" component={ Buttons } /> */}
          <ProtectedRoute path="/profile" component={InfoProfile} />
          <ProtectedRoute path="/change-password" component={ChangePasswordProfile} />
          {/* <ProtectedRoute path="/basic-ui/dropdowns" component={ Dropdowns } /> */}
          {/* <ProtectedRoute path="/basic-ui/typography" component={ Typography } /> */}
          {/* <ProtectedRoute path="/form-Elements/basic-elements" component={ BasicElements } /> */}
          {/* <ProtectedRoute path="/tables/basic-table" component={ BasicTable } /> */}

          <ProtectedRoute path="/add-friend/create" component={AddFriendCreate} />
          <ProtectedRoute path="/add-friend/list" component={AddFriendList} />
          <ProtectedRoute path="/add-friend/huong-dan" component={AddFriendHuongDan} />

          <ProtectedRoute path="/permission/create" component={PermissionCreate} />
          <ProtectedRoute path="/permission/list" component={PermissionList} />

          <ProtectedRoute path="/bang-gia" component={BanggiaList} />
          <ProtectedRoute path="/so-du" component={SoduList} />
          <ProtectedRoute path="/thongbao/tatca" component={ThongbaoList} />
          <ProtectedRoute path="/qty-list" component={PackageBuyList} />

          <ProtectedRoute path="/account/create" component={AccountCreate} />
          <ProtectedRoute path="/account/list" component={AccountList} />
          <ProtectedRoute path="/account/edit" component={AccountEdit} />
          <ProtectedRoute path="/quantity/list" component={QuantityList} />

          <ProtectedRoute path="/duyet/create" component={DuyetCreate} />
          <ProtectedRoute path="/duyet/list" component={DuyetList} />

          <ProtectedRoute path="/gmobile/dktt/create" component={GmobileDkttCreate} />
          <ProtectedRoute path="/gmobile/dktt/huong-dan" component={GmobileDkttHuongDan} />
          <ProtectedRoute path="/gmobile/dktt/list" component={GmobileDkttList} />
          <ProtectedRoute path="/gmobile/dktt/order" component={OrderList} />


          <ProtectedRoute path="/msale/dnts/create" component={MsaleDntsCreate} />
          <ProtectedRoute path="/msale/dnts/huong-dan" component={MsaleDntsHuongDan} />
          <ProtectedRoute path="/msale/dnts/list" component={MsaleDntsList} />

          <ProtectedRoute path="/msale/dncngt/create" component={MsaleDnCnGtCreate} />
          <ProtectedRoute path="/msale/dncngt/huong-dan" component={MsaleDnCnGtHuongDan} />
          <ProtectedRoute path="/msale/dncngt/list" component={MsaleDnCnGtList} />

          <ProtectedRoute path="/vnsky/create" component={VnskyDkttCreate} />
          <ProtectedRoute path="/vnsky/huong-dan" component={VnskyDkttHuongDan} />
          <ProtectedRoute path="/vnsky/list" component={VnskyDkttList} />

          <ProtectedRoute path="/myitel/create" component={MyItelDkttCreate} />
          <ProtectedRoute path="/myitel/huong-dan" component={MyItelDkttHuongDan} />
          <ProtectedRoute path="/myitel/list" component={MyItelDkttList} />

          <Route path="/dang-nhap" component={Login} />

          {/* <Route path="/basic-ui/buttons" component={ Buttons } />
          <Route path="/basic-ui/dropdowns" component={ Dropdowns } />
          <Route path="/basic-ui/typography" component={ Typography } />


          <Route path="/form-Elements/basic-elements" component={ BasicElements } />

          <Route path="/tables/basic-table" component={ BasicTable } /> */}


          <Route path="/icons/mdi" component={Mdi} />

          {/* 
          <Route path="/charts/chart-js" component={ ChartJs } />


          <Route path="/user-pages/login-1" component={ Login } />
          <Route path="/user-pages/register-1" component={ Register1 } />
          <Route path="/user-pages/lockscreen" component={ Lockscreen } />

          <Route path="/error-pages/error-404" component={ Error404 } />
          <Route path="/error-pages/error-500" component={ Error500 } />

          <Route path="/general-pages/blank-page" component={ BlankPage } />
 */}

          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;