import {
  CLEAR_ERRORS,
  LOAD_ON_REQUEST,
  GET_SUCCESS,
  GET_FAIL,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_THONGBAO_SUCCESS,
  GET_XEMALL_SUCCESS,

} from "../constants/thongbaoConstants";

export const thongbaoReducer = (state = { thongbao: {} }, action) => {
  switch (action.type) {
    case LOAD_ON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        redirect: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        thongbao: action.payload,
      };
      case GET_XEMALL_SUCCESS:
        return {
          ...state,
          loading: false,
          thongbao_all: action.payload,
        };
      
      case GET_THONGBAO_SUCCESS:
        return {
          ...state,
          loading: false,
          thongbao: action.payload,
        };
      
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        thongbao: {},
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        is_redirect: true,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        error_submit: null,
        success_submit: null,
        is_redirect: null
      };
    default:
      return state;
  }
};
