import {
  CLEAR_ERRORS, LOAD_ON_REQUEST, GET_SUCCESS, GET_FAIL, GET_EXPORT,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_THONGBAO_SUCCESS,
  GET_XEMALL_SUCCESS,
}
  from "../constants/thongbaoConstants";
import axios from "axios";

const URL = process.env.REACT_APP_BASE_URL || ''
export const getThongbaoMoinhat = (postData = {}) => async (dispatch) => {
  try {
    // dispatch({ type: LOAD_ON_REQUEST});
    const config = { headers: { Authorization: `Bearer ` + localStorage.getItem('access_token') }, params: postData };
    const link = URL + `/api/thong-bao/moi-nhat`;
    const { data } = await axios.get(link, config);
    if (data.error_code !== 1) {
      return dispatch({ type: GET_FAIL, payload: data.message });
    }
    return dispatch({ type: GET_THONGBAO_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_FAIL, payload: error.message });
  }
};

export const getThongbaoTatca = (postData = {}) => async (dispatch) => {
  try {
    dispatch({ type: LOAD_ON_REQUEST });
    const config = { headers: { Authorization: `Bearer ` + localStorage.getItem('access_token') }, params: postData };
    const link = URL + `/api/thong-bao/xem-tatca`;
    const { data } = await axios.get(link, config);
    if (data.error_code !== 1) {
      return dispatch({ type: GET_FAIL, payload: data.message });
    }
    return dispatch({ type: GET_XEMALL_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({ type: GET_FAIL, payload: error.message });
  }
};


export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
export const clearSubmits = () => async (dispatch) => {
  dispatch({ type: SUBMIT_CLEAR });
};
