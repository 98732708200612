import {
  CLEAR_ERRORS,
  LOAD_ON_REQUEST,
  GET_SUCCESS,
  GET_FAIL,
  GET_EXPORT,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_HUONGDAN_SUCCESS,
  GET_HUONGDAN_FAIL,
  GET_CHANGE_IMEI_SUCCESS,
  UPDATE_STATUS_ERROR,
  UPDATE_STATUS_SUCCESS,
  GET_ACCOUNT_MY_SUCCESS,
  GET_SENT_SMS_SUCCESS,
  GET_USSD_SUCCESS,
  GET_CALL_SUCCESS,
  GET_MULTIPLE_CMD_SUCCESS,
  GET_MULTIPLE_CMD_SCRIPT_SUCCESS,

} from "../constants/transactionConstants";

export const changeImeiReducer = (state = { changeImei: {} }, action) => {
  switch (action.type) {
    case LOAD_ON_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        redirect: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        change_imei: action.payload,
      };
    case UPDATE_STATUS_ERROR:
      return {
        ...state,
        loading: false,
        error_submit: action.payload,
      };
    case UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        success_submit: action.payload,
      };
    case GET_CHANGE_IMEI_SUCCESS:
      return {
        ...state,
        loading: false,
        change_imei: action.payload,
      };
    case GET_ACCOUNT_MY_SUCCESS:
      return {
        ...state,
        loading: false,
        account_my: action.payload,
      };
    case GET_SENT_SMS_SUCCESS:
      return {
        ...state,
        loading: false,
        sent_sms: action.payload,
      };
    case GET_USSD_SUCCESS:
      return {
        ...state,
        loading: false,
        ussd: action.payload,
      };
    case GET_CALL_SUCCESS:
      return {
        ...state,
        loading: false,
        call: action.payload,
      };
    case GET_MULTIPLE_CMD_SUCCESS:
      return {
        ...state,
        loading: false,
        multiple_cmd: action.payload,
      };
    case GET_MULTIPLE_CMD_SCRIPT_SUCCESS:
      return {
        ...state,
        loading: false,
        multiple_cmd_script: action.payload,
      };

    case GET_EXPORT:
      return {
        ...state,
        loading: false,
        download: action.payload
      };
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        change_imei: {},
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        is_redirect: true,
      };
    case GET_HUONGDAN_SUCCESS:
      return {
        ...state,
        loading: false,
        huongdan: action.payload,
      };
    case GET_HUONGDAN_FAIL:
      return {
        ...state,
        loading: false,
        huongdan: {},
        message: action.payload,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        error_submit: null,
        success_submit: null,
        is_redirect: null
      };
    default:
      return state;
  }
};
