import {
  CLEAR_ERRORS,
  LOAD_VNSKY_REQUEST,
  GET_SUCCESS,
  GET_FAIL,
  GET_EXPORT,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_VNSKY_SUCCESS,
  GET_HUONGDAN_SUCCESS,
  GET_HUONGDAN_FAIL,

} from "../constants/vnskyConstants";

export const vnskyReducer = (state = { vnsky: {} }, action) => {
  switch (action.type) {
    case LOAD_VNSKY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        redirect: null,
        redirect_role: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload,
      };
    case GET_HUONGDAN_SUCCESS:
      return {
        ...state,
        loading: false,
        huongdan: action.payload,
      };
    case GET_HUONGDAN_FAIL:
      return {
        ...state,
        loading: false,
        huongdan: {},
        message: action.payload,
      };
    case GET_VNSKY_SUCCESS:
      return {
        ...state,
        loading: false,
        list_vnsky: action.payload,
      };
    case GET_EXPORT:
      return {
        ...state,
        loading: false,
        download: action.payload,
      };
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        list_vnsky: {},
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success_submit: action.payload.message,
        is_redirect: true,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error_submit: action.payload,
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        detail: {},
        error_submit: null,
        success_submit: null,
        is_redirect: null
      };
    default:
      return state;
  }
};
