import {
  CLEAR_ERRORS,
  LOAD_GMOBILE_REQUEST,
  GET_SUCCESS,
  GET_FAIL,
  GET_EXPORT,
  SUBMIT_SUCCESS,
  SUBMIT_FAIL,
  SUBMIT_CLEAR,
  GET_GMOBLE_DKTT_SUCCESS,
  GET_HUONGDAN_SUCCESS,
  GET_HUONGDAN_FAIL,
  GET_GMOBILE_SEARCH_PHONE_SUCCESS,
  GET_GMOBLE_DKTT_DETAIL_SUCCESS,

} from "../constants/gmobileConstants";

export const gmobileReducer = (state = { gmobile: {} }, action) => {
  switch (action.type) {
    case LOAD_GMOBILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        success: null,
        download: null,
        redirect: null,
        redirect_role: null,
        error_submit: null,
        success_submit: null,
      };
    case GET_SUCCESS:
      return {
        ...state,
        loading: false,
        account: action.payload,
      };
    case GET_HUONGDAN_SUCCESS:
      return {
        ...state,
        loading: false,
        huongdan: action.payload,
      };
    case GET_HUONGDAN_FAIL:
      return {
        ...state,
        loading: false,
        huongdan: {},
        message: action.payload,
      };
    case GET_GMOBLE_DKTT_SUCCESS:
      return {
        ...state,
        loading: false,
        list_gmobile_dktt: action.payload,
      };

    case GET_GMOBLE_DKTT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        detail_gmobile_dktt: action.payload,
        is_redirect: true,
      };
    case GET_EXPORT:
      return {
        ...state,
        loading: false,
        download: action.payload,
      };
    case GET_FAIL:
      return {
        ...state,
        loading: false,
        list_gmobile_dktt: {},
        message: action.payload,
      };
    case SUBMIT_SUCCESS:
      return {
        ...state,
        loading: false,
        success_submit: action.payload.message,
        is_redirect: true,
      };
    case GET_GMOBILE_SEARCH_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
        list_phone: action.payload.data,
        success_submit: action.payload.message,
        recheck: true,
      };
    case SUBMIT_FAIL:
      return {
        ...state,
        loading: false,
        error_submit: action.payload,
        detail_gmobile_dktt: {},
      };
    case SUBMIT_CLEAR:
      return {
        ...state,
        loading: false,
        detail: {},
        error_submit: null,
        success_submit: null,
        is_redirect: null,
        detail_gmobile_dktt: {},
      };
    default:
      return state;
  }
};
